<!-- 影厅 -->
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>分析</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card" v-if="!companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a')">
      <div slot="header" class="clearfix">
        <span>总销售额: ￥{{ onlineMoneyTotal + offlineMoneyTotal }}</span>
      </div>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-card shadow="always">
            平台售票数: {{ onlineGoodsTotal }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            平台销售额: ￥{{ onlineMoneyTotal }}
          </el-card>
        </el-col>
        <el-col :span="6">
          <!-- <el-card shadow="always">
            门店售票数: {{ offlineGoodsTotal }}张
          </el-card> -->
        </el-col>
        <el-col :span="6">
          <!-- <el-card shadow="always">
            门店销售额: ￥{{ offlineMoneyTotal }}
          </el-card> -->
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card" v-if="!companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a')">
      <div slot="header" class="clearfix">
        <span>今日销售额: ￥{{ todayOnlineMoneyTotal + todayOfflineMoneyTotal }}</span>
      </div>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-card shadow="always">
            平台售票数: {{ todayOnlineGoodsTotal }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            平台销售额: ￥{{ todayOnlineMoneyTotal }}
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            门店售票数: {{ todayOfflineGoodsTotal }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            门店销售额: ￥{{ todayOfflineMoneyTotal }}
          </el-card>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card" v-if="companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a')">
      <div slot="header" class="clearfix">
        <span>总销售额: ￥{{ totalMoney }}</span>
      </div>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-card shadow="always">
            已核销票数: {{ checkedNum }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            未核销票数: {{ unCheckNum }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            门店售票数: {{ sellNum }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            门店销售额: ￥{{ sellMoney }}
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card" v-if="companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a')">
      <div slot="header" class="clearfix">
        <span>今日销售额: ￥{{ todayTotalMoney }}</span>
      </div>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-card shadow="always">
            已核销票数: {{ todayCheckedNum }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            未核销票数: {{ todayUnCheckNum }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            门店售票数: {{ todaySellNum }}张
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="always">
            门店销售额: ￥{{ todaySellMoney }}
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <!-- 卡片 -->
    <el-card>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-card shadow="always">
      <!-- 2.为ECharts准备一个具备大小（宽高）的Dom -->
            <div id="goodsTotalEchart" style="width: 100%; height: 400px;"></div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="always">
            <div id="moneyTotalEchart" style="width: 100%; height: 400px"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-card shadow="always">
            <div id="goodsGroupTotalEchart" style="width: 100%; height: 400px"></div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="always">
            <div id="moneyGroupTotalEchart" style="width: 100%; height: 400px"></div>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// 1.导入echarts
const echarts = require("echarts");

export default {
  name: "count",
  data() {
    return {
      companyId: window.sessionStorage.getItem("companyId"),
      onlineGoodsTotal: 0,
      onlineMoneyTotal: 0,
      offlineGoodsTotal: 0,
      offlineMoneyTotal: 0,
      todayOnlineGoodsTotal: 0,
      todayOnlineMoneyTotal: 0,
      todayOfflineGoodsTotal: 0,
      todayOfflineMoneyTotal: 0,
      // 瑶里景区数据
      checkedNum: 0,
      unCheckNum: 0,
      sellNum: 0,
      sellMoney: 0,
      todayCheckedNum: 0,
      todayUnCheckNum: 0,
      todaySellNum: 0,
      todaySellMoney: 0,
      totalMoney: 0,
      todayTotalMoney: 0,
    };
  },
  created() {
    let isAdmin = window.sessionStorage.getItem("isAdmin");
    if (isAdmin === "0") {
      this.$router.push("/message/order");
      window.sessionStorage.setItem("activePath", "/message/order")
    }
    if (this.companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a')) {
      this.getGoodsTotalYl()
    } else {
      this.getGoodsTotal()
    }
  },
  // 此时页面上的元素已经被渲染完毕
  mounted() {
    let xData = []
    for (let i = -6; i < 1; i++) {
      const curDay = this.getDay(i)
      xData.push(curDay)
    }
    console.log(xData)
    let yData = []
    const params = {
      groupList: xData
    }

    this.$axios({
      method: 'get',
      url: "/manager/goodsTotalByPayType",
      params,
    })
    .then((res) => {
      this.initGoodsGroupTotalEchart(res.data)
      this.initMoneyGroupTotalEchart(res.data)
      this.initGoodsTotalEchart(xData ,res.data.map(o => { return parseInt(o.平台销量) + parseInt(o.门店销量) + parseInt(o.第三方销量) }))
      this.initMoneyTotalEchart(xData ,res.data.map(o => { return parseInt(o.平台销售额) + parseInt(o.门店销售额) + parseInt(o.第三方销售额) }))
    })
    .catch((err) => err);

  },
  methods: {
    getGoodsTotalYl() {
      this.$axios({
        method: 'get',
        url: "/manager/goodsTotalYl",
      })
      .then((res) => {
        this.checkedNum = res.data.checkedNum
        this.unCheckNum = res.data.unCheckNum
        this.sellNum = res.data.sellNum
        this.sellMoney = res.data.sellMoney
        this.todayCheckedNum = res.data.todayCheckedNum
        this.todayUnCheckNum = res.data.todayUnCheckNum
        this.todaySellNum = res.data.todaySellNum
        this.todaySellMoney = res.data.todaySellMoney
        this.totalMoney = res.data.totalMoney
        this.todayTotalMoney = res.data.todayTotalMoney
      })
      .catch((err) => err);

    },
    getGoodsTotal() {
      let params = {
        endTime: new Date(),
        type: '1'
      }

      this.$axios({
        method: 'get',
        url: "/manager/goodsTotal",
        params,
      })
      .then((res) => {
        this.onlineGoodsTotal = res.data[0].total
        this.onlineMoneyTotal = res.data[0].totalMoney
      })
      .catch((err) => err);

      params.type = '2'
      this.$axios({
        method: 'get',
        url: "/manager/goodsTotal",
        params,
      })
      .then((res) => {
        this.offlineGoodsTotal = res.data[0].total
        this.offlineMoneyTotal = res.data[0].totalMoney
      })
      .catch((err) => err);

      params.startTime = new Date()
      this.$axios({
        method: 'get',
        url: "/manager/goodsTotal",
        params,
      })
      .then((res) => {
        this.todayOfflineGoodsTotal = res.data[0].total
        this.todayOfflineMoneyTotal = res.data[0].totalMoney
      })
      .catch((err) => err);

      params.type = '1'
      this.$axios({
        method: 'get',
        url: "/manager/goodsTotal",
        params,
      })
      .then((res) => {
        this.todayOnlineGoodsTotal = res.data[0].total
        this.todayOnlineMoneyTotal = res.data[0].totalMoney
      })
      .catch((err) => err);


    },

    initGoodsTotalEchart(xData, yData) {
      //3. 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("goodsTotalEchart"));
      //4. 指定图表的配置项和数据
      var option = {
        //标题
        title: {
          text: "近一周销量统计",
        },
        //工具箱
        //保存图片
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        //图例-每一条数据的名字叫销量
        legend: {
          data: ["销量"],
        },
        grid: {
          // left: "10%",
          // bottom: "35%",
          y2:140
        },
        //x轴
        xAxis: {
          data: xData,
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
        },
        //y轴没有显式设置，根据值自动生成y轴
        yAxis: {
          type: 'value',

        },
        //数据-data是最终要显示的数据
        series: [
          {
            name: "销量",
            type: "line",
            data: yData,
          },
        ],
      };

      //5.展示数据 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initMoneyTotalEchart(xData, yData) {
      //3. 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("moneyTotalEchart"));
      //4. 指定图表的配置项和数据
      var option = {
        //标题
        title: {
          text: "近一周销售额统计",
        },
        //工具箱
        //保存图片
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        //图例-每一条数据的名字叫销量
        legend: {
          data: ["销售额"],
        },
        grid: {
          // left: "10%",
          // bottom: "35%",
          y2:140
        },
        //x轴
        xAxis: {
          data: xData,
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
        },
        //y轴没有显式设置，根据值自动生成y轴
        yAxis: {},
        //数据-data是最终要显示的数据
        series: [
          {
            name: "销售额",
            type: "line",
            data: yData,
          },
        ],
      };

      //5.展示数据 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initGoodsGroupTotalEchart(data) {
      var myChart = echarts.init(document.getElementById("goodsGroupTotalEchart"));
      var option = {
        //标题
        title: {
          text: "近一周各渠道销量统计",
        },
        //工具箱
        //保存图片
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
            },
          },
        },
        legend: {},
        tooltip: {},
        dataset: {
          dimensions: ['product', '平台销量', '门店销量', '第三方销量'],
          source: data
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
      };

      myChart.setOption(option);
    },
    initMoneyGroupTotalEchart(data) {
      var myChart = echarts.init(document.getElementById("moneyGroupTotalEchart"));
      var option = {
        //标题
        title: {
          text: "近一周各渠道销售额统计",
        },
        //工具箱
        //保存图片
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              show: true,
            },
          },
        },
        legend: {},
        tooltip: {},
        dataset: {
          dimensions: ['product', '平台销售额', '门店销售额', '第三方销售额'],
          source: data
        },
        xAxis: { type: 'category' },
        yAxis: {},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
      };

      myChart.setOption(option);
    },
    getDay(day){

      var today = new Date();



      var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;



      today.setTime(targetday_milliseconds); //注意，这行是关键代码



      var tYear = today.getFullYear();

      var tMonth = today.getMonth();

      var tDate = today.getDate();

      tMonth = this.doHandleMonth(tMonth + 1);

      tDate = this.doHandleMonth(tDate);

      return tYear+"-"+tMonth+"-"+tDate;

  },

    doHandleMonth(month){

        var m = month;

        if(month.toString().length == 1){

            m = "0" + month;

        }

        return m;

    },
  },
};
</script>

<style>
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 100%;
  }
</style>
